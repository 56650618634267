<style lang="scss" scoped>
	img {
		width: 100%;
	}
</style>

<template>
	<section>
		<h1>Impressionen</h1>
		<img src="@/../public/img/im.jpg" alt="Klavierstimmer Andreas Schneider" />
	</section>
</template>
